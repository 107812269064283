<template>
  <b-card class="rounded-0 h-100 m-0">
    <b-row>
      <b-form class="p-2">
        <b-form-group label="Movie :- ">
          <p class="value-text">{{ data.titles }}</p>
        </b-form-group>
        <b-form-group label="Licensor/Licensee :- ">
          <p class="value-text">{{ data.company }}</p>
        </b-form-group>
        <b-form-group label="MG :- ">
          <p class="value-text">{{ formatCurrency(data.mgadvance) }}</p>
        </b-form-group>
        <b-form-group label="Title :- ">
          <p class="value-text">{{ data.milestone_name }}</p>
        </b-form-group>
        <b-form-group label="% :- ">
          <p class="value-text">{{ data.payable }}</p>
        </b-form-group>
        <b-form-group label="Amount :- ">
          <p class="value-text">
            {{ formatCurrency(data.amountpayable) }} {{ data.currency }}
          </p>
        </b-form-group>
        <b-form-group label="Due Date :- ">
          <flat-pickr
            :disabled="true"
            placeholder="Due Date"
            class="form-control"
            v-model="form.duedate"
            :config="{
              ...$flatPickrConfig,
            }"
          />
        </b-form-group>
        <b-form-group label="Comment">
          <b-form-input v-model="form.comment" />
        </b-form-group>
        <b-form-group label="Amount Paid CHF">
          <b-form-input v-model="form.amountpaid" />
        </b-form-group>
        <b-form-group label="Payment Date :- ">
          <flat-pickr
            placeholder="Payment Date"
            class="form-control"
            v-model="form.paymentdate"
            :config="{
              ...$flatPickrConfig,
            }"
          />
        </b-form-group>
        <b-row style="justify-content: flex-end; margin-inline: 10px">
          <b-button
            variant="primary"
            @click="
              () => {
                showModal('Are you sure want to submit?', 'save').then(
                  (value) => {
                    if (value) saveMilestone();
                  }
                );
              }
            "
            >Save</b-button
          >
        </b-row>
      </b-form>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import * as ContractServices from "@/apiServices/ContractServices";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import { formatCurrency } from "@/utils/helpers";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import showModal from "../../../components/ModalConfirmation";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    flatPickr,
    vSelect,
    BFormSelect,
    BFormSelectOption,
  },
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      form: {
        duedate: "",
        comment: "",
        amountpaid: "",
        paymentdate: "",
      },
    };
  },
  watch: {
    data(oldVal, newVal) {
      this.form = {
        ...this.form,
        duedate: this.data.duedate,
        comment: this.data.comment,
        amountpaid: this.data.amountpaid,
        paymentdate: this.data.paymentdate,
      };
    },
  },
  methods: {
    showModal,
    formatCurrency,
    moment,
    async saveMilestone() {
      try {
        const response = await ContractServices.SaveContractMileStones({
          id: this.data.id,
          ...this.form,
        });

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "success",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$emit("saved");
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Failed to save",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.error("Error in save milestone");
      }
    },
  },
};
</script>

<style>
hr.dotted {
  border-top: 2px dotted #999;
  width: 96%;
}
.value-text {
  color: black;
  font-weight: bold;
}
</style>
